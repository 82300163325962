import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";

import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `#${history.location.pathname}`; // get current path

    const subMenuLi = document.querySelectorAll(".sub-menu > li");
    for (let i = 0; i < subMenuLi.length; i++) {
      subMenuLi[i].onclick = function (event) {
        event.stopPropagation();
      };
    }

    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, "li");
          // console.log("parentLi",parentLi);
          //alert('ok');
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains("open"))
          ) {
            menuLi[j].classList.remove("open");
          }
        }
        this.classList.toggle("open");
        event.stopPropagation();
      };
    }

    const activeLi = document.querySelector('a[href="#' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul

      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
        console.log(this.closest(activeNav, "li"));
      } else {
        this.closest(activeLi, "li").classList.add("open");
        console.log("this", this.closest(activeNav, "li"));
      }
    } catch (error) { }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function (fn) {
        if (typeof document.body[fn] == "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) { }

    return null;
  }

  render() {
    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          <li>
            <NavLink to="/app/dashboard/index">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">Dashboard</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/app/customers">
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">Patients </span>
            </NavLink>
          </li>
          {/* <li  >
            <NavLink to="/app/drivers">
              <i className="zmdi zmdi-local-taxi zmdi-hc-fw" />
              <span className="nav-text">Drivers </span>
            </NavLink>
          </li> */}
          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-local-taxi zmdi-hc-fw" />
              <span className="nav-text">Drivers</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/drivers/list"
                >
                  <i className="zmdi zmdi-local-taxi" />
                  <span className="nav-text">All </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/drivers/approved"
                >
                  <i className="zmdi zmdi-local-taxi" />
                  <span className="nav-text">Approved </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/drivers/new">
                  <i className="zmdi zmdi-local-taxi" />
                  <span className="nav-text">New </span>
                </NavLink>
              </li>
            </ul>
          </li>
          {/* <li  >
            <NavLink to="/app/car-types">
              <i className="zmdi zmdi-copy zmdi-hc-fw" />
              <span className="nav-text">Car Types </span>
            </NavLink>
          </li> */}

          {/* <li  >
            <NavLink to="/app/trips">
              <i className="zmdi zmdi-receipt zmdi-hc-fw" />
              <span className="nav-text">Orders </span>
            </NavLink>
          </li> */}
          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-receipt zmdi-hc-fw" />
              <span className="nav-text">Orders</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/trips/list">
                  <i className="zmdi zmdi-receipt" />
                  <span className="nav-text">All</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/trips/purolatorList">
                  <i className="zmdi zmdi-receipt" />
                  <span className="nav-text">Pickup Orders</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/trips/Serverless">
                  <i className="zmdi zmdi-receipt" />
                  <span className="nav-text">Serverless Orders</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/trips/purolatorServerless">
                  <i className="zmdi zmdi-receipt" />
                  <span className="nav-text">Serverless Pickup Orders</span>
                </NavLink>
              </li>

              {/* <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/trips/completed"
                >
                  <i className="zmdi zmdi-receipt" />
                  <span className="nav-text">Completed</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/trips/progress"
                >
                  <i className="zmdi zmdi-receipt" />
                  <span className="nav-text">In Progress</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/trips/new">
                  <i className="zmdi zmdi-receipt" />
                  <span className="nav-text">Decline/Cancelled</span>
                </NavLink>
              </li> */}
            </ul>
          </li>
          <li>
            <NavLink to="/app/categories">
              <i className="zmdi zmdi-star-circle zmdi-hc-fw" />
              <span className="nav-text">Categories</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/units">
              <i className="zmdi zmdi-format-clear-all zmdi-hc-fw" />
              <span className="nav-text">Units</span>
            </NavLink>
          </li>

          {/* <li>
            <NavLink to="/app/categories/list">
              <i className="zmdi zmdi-dot-circle zmdi-hc-fw" />
              <span className="nav-text">Categories</span>
            </NavLink>
          </li> */}
          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-store zmdi-hc-fw" />
              <span className="nav-text">Pharmacy</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/vendor/list">
                  <i className="zmdi zmdi-store" />
                  <span className="nav-text">All </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/vendor/approved"
                >
                  <i className="zmdi zmdi-store" />
                  <span className="nav-text">Approved </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/vendor/new">
                  <i className="zmdi zmdi-store" />
                  <span className="nav-text">New </span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-money-box zmdi-hc-fw" />
              <span className="nav-text">Expenses</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/expenses/filter">
                  <i className="zmdi zmdi-money-box zmdi-hc-fw" />
                  <span className="nav-text">Filter Pharmacy</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/expenses/all">
                  <i className="zmdi zmdi-money-box zmdi-hc-fw" />
                  <span className="nav-text">All Pharmacy</span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/app/payment">
              <i className="zmdi zmdi-assignment" />
              <span className="nav-text">Payment traceability</span>
            </NavLink>
          </li>

          {/* <li>
            <NavLink to="/app/expenses">
              <i className="zmdi zmdi-money-box zmdi-hc-fw" />
              <span className="nav-text">Pharmacy Expenses</span>
            </NavLink>
          </li> */}

          <li>
            <NavLink to="/app/pricing">
              <i className="zmdi zmdi-money-box zmdi-hc-fw" />
              <span className="nav-text">Pricing </span>
            </NavLink>
          </li>
          {/* <li  >
            <NavLink to="/app/cuisines">
              <i className="zmdi zmdi-star zmdi-hc-fw" />
              <span className="nav-text">Cuisines</span>
            </NavLink>
          </li>
          <li  >
            <NavLink to="/app/promotions">
              <i className="zmdi zmdi-labels zmdi-hc-fw" />
              <span className="nav-text">Promotions</span>
            </NavLink>
          </li> */}

          <li>
            <NavLink to="/app/banner-image">
              <i className="zmdi zmdi-collection-image-o zmdi-hc-fw" />
              <span className="nav-text">Banner Image</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/promo-codes">
              <i className="zmdi zmdi-label zmdi-hc-fw" />
              <span className="nav-text">Promo Codes</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/app/contentPages">
              <i className="zmdi zmdi-file-text zmdi-hc-fw" />
              <span className="nav-text">Content Pages</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/faq">
              <i className="zmdi zmdi-comments zmdi-hc-fw" />
              <span className="nav-text">FAQ</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/notification">
              <i className="zmdi zmdi-notifications-active zmdi-hc-fw" />
              <span className="nav-text">Push Notification</span>
            </NavLink>
          </li>

          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">Settings</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/users/list">
                  <i className="zmdi zmdi-account" />
                  <span className="nav-text">Admin Settings</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/settings/basic"
                >
                  <i class="zmdi zmdi-album"></i>

                  <span className="nav-text">Basic Settings</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/settings/installation"
                >
                  <i class="zmdi zmdi-remote-control"></i>

                  <span className="nav-text">Installation Settings</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/settings/mail"
                >
                  <i class="zmdi zmdi-email"></i>

                  <span className="nav-text">Mail templates</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/settings/sms"
                >
                  <i class="zmdi zmdi-comment-text"></i>

                  <span className="nav-text">SMS Templates</span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <a href="https://meds-on-wheels.com/mdsadmin/login" target="_blank">
              <i className="zmdi zmdi-arrow-right-top zmdi-hc-fw" />
              <span className="nav-text">Go to Old Panel</span>
            </a>
          </li>
        </ul>
      </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);
